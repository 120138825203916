import React, { ReactElement, useMemo } from "react"
import { MenuItem } from "@mui/material"
import { EEquipmentUserMode } from "shared"

import { useTypedSelectorHook } from "@utils"

type IEquipmentUserModesObject = Record<EEquipmentUserMode, ReactElement>

interface IUseUserModeSelectReturn {
  isReadOnly: boolean
  menuItemList: ReactElement[]
}

export const useUserModeSelect = (userMode: EEquipmentUserMode): IUseUserModeSelectReturn => {
  const { equipmentUserModeHandbook } = useTypedSelectorHook(({ handbooks }) => handbooks)

  const currentUserMode = useMemo(
    () => equipmentUserModeHandbook.find(({ key }) => key === userMode),
    [equipmentUserModeHandbook, userMode]
  )

  const allMenuItems = useMemo(() => {
    const equipmentUserModesObject = {} as IEquipmentUserModesObject

    equipmentUserModeHandbook.forEach(({ key, title, read_only }) => {
      equipmentUserModesObject[key] = <MenuItem value={key} children={title} key={key} disabled={read_only} />
    })

    return equipmentUserModesObject
  }, [equipmentUserModeHandbook])

  if (!currentUserMode || !currentUserMode?.available_modes) {
    return {
      isReadOnly: true,
      menuItemList: [],
    }
  }

  if (currentUserMode.read_only) {
    return {
      isReadOnly: currentUserMode.read_only,
      menuItemList: [allMenuItems[currentUserMode.key]],
    }
  }

  const availableKeys = [userMode, ...currentUserMode.available_modes]

  const menuItemList = availableKeys.map((key) => allMenuItems[key])

  return {
    isReadOnly: currentUserMode.read_only,
    menuItemList,
  }
}
