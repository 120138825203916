import React, { FC, useMemo } from "react"
import { Typography } from "@mui/material"
import { Alert, getDataLists, SkeletonWrapper, TComponentSize } from "shared"

import { useLocalizationContext } from "@context"
import { TEquipmentCondition } from "@utils"

interface IEquipmentConditionBar {
  condition: TEquipmentCondition
  isPending?: boolean
  size?: TComponentSize
}

/**
 * Статус бар состояния оборудования
 *
 * @returns {FC<IEquipmentConditionBar>} - FC<IEquipmentCondition>
 */
export const EquipmentConditionBar: FC<IEquipmentConditionBar> = ({ condition, isPending = false, size = "small" }) => {
  const { l } = useLocalizationContext()

  const { getEquipmentCondition } = useMemo(() => getDataLists(l), [l])

  return (
    <SkeletonWrapper isPending={isPending} width={126} height={40}>
      {!condition ? (
        <Typography variant="subtitle2" children={l.equipment.conditionTypes.notTracked} />
      ) : (
        <Alert type={condition} size={size} text={getEquipmentCondition(condition)} />
      )}
    </SkeletonWrapper>
  )
}
