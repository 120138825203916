import { useMemo } from "react"
import { EProtocol } from "shared"

import { useTypedSelectorHook } from "@utils"
import { IJournalEvent } from "@types"

export const useHadbookedRows = (journalEventList: IJournalEvent[]): IJournalEvent[] => {
  const {
    handbooks: {
      moduleEventNameHandbook: modNames,
      moduleEventTypeHandbook: modTypes,
      externalEventNameHandbook: extNames,
    },
  } = useTypedSelectorHook((state) => state)

  const rows = useMemo(() => {
    if (!modNames || !extNames || !modTypes) return journalEventList

    return journalEventList.map((event) => {
      const nameList = event.protocol === EProtocol.ECHO ? modNames : extNames

      const event_name = nameList.find((option) => option.key === event.event_name)?.title ?? event.event_name
      const event_type = modTypes.find((option) => option.key === event.event_type)?.title ?? event.event_type

      return {
        ...event,
        event_name,
        event_type,
      }
    }) as IJournalEvent[]
  }, [journalEventList, modNames, modTypes, extNames])

  return rows
}
