import React, { FC } from "react"
import { IconEquipping, SkeletonWrapper } from "shared"

import { TEquipmentCondition } from "@utils"

interface IEquipmentConditionIcon {
  condition: TEquipmentCondition
  isPending: boolean
}

/**
 * Иконка статуса состояния оборудования
 *
 * @returns {FC<IEquipmentConditionIcon>} - FC<IEquipmentConditionIcon>
 */
export const EquipmentConditionIcon: FC<IEquipmentConditionIcon> = ({ condition, isPending }) => (
  <SkeletonWrapper isPending={isPending} children={<IconEquipping condition={condition} />} height={40} width={40} />
)
