import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { EProtocol, ILocale, dateTimeFormatter } from "shared"

/**
 * Данные для заголовков ячеек таблицы "Журнал событий"
 */
export function getColumns(locale: ILocale): GridColDef[] {
  return [
    { field: "id", hide: true, type: "number" },
    {
      field: "time",
      headerName: locale.events.eventTime,
      type: "string",
      minWidth: 150,
      flex: 0.08,
      renderCell: (params: GridRenderCellParams): string => dateTimeFormatter(params.value),
    },
    {
      field: "equipment_name",
      headerName: locale.equipment.equipment,
      type: "string",
      minWidth: 280,
      flex: 0.1,
    },
    {
      field: "item_name",
      headerName: locale.ui.source,
      type: "string",
      minWidth: 280,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams): string =>
        params.row.protocol !== EProtocol.ECHO ? "—" : params.value,
    },
    {
      field: "event_name",
      headerName: locale.ui.nameTitleMixed,
      type: "string",
      minWidth: 280,
      flex: 0.2,
    },
    {
      field: "event_type",
      headerName: locale.ui.type,
      type: "string",
      minWidth: 200,
      flex: 0.1,
    },
  ]
}
