import React, { FC, Fragment } from "react"
import { FormControl, Select, Typography } from "@mui/material"
import { EEquipmentUserMode, SkeletonWrapper } from "shared"

import { useLocalizationContext } from "@context"
import { TChangeUserMode } from "@utils"
import { useUserModeSelect } from "./_useUserModeSelect.hook"

interface IUserModeSelect {
  equipmentId: number
  userMode: EEquipmentUserMode
  changeUserMode: TChangeUserMode
  isPending: boolean
}

// TODO: добавлен костыль по проверке оборудования на соответствие айдишникам из demo-account,
// убрать пропс equipmentId, когда завершится настройка репы для демо-акка

/**
 * Селект переключения режима оборудования
 *
 * @param {EEquipmentUserMode} userMode - Режим работы оборудования
 * @param {TChangeUserMode} changeUserMode - Изменение режима работы оборудования
 *
 * @returns {FC<IUserModeSelect>} - FC<IUserModeSelect>
 */
export const UserModeSelect: FC<IUserModeSelect> = ({ equipmentId, userMode, changeUserMode, isPending }) => {
  const { l } = useLocalizationContext()
  const { isReadOnly, menuItemList } = useUserModeSelect(userMode)

  const isDisabled = isPending || isReadOnly

  return (
    <Fragment>
      <Typography variant="subtitle2" children={`${l.equipment.equipmentOperationMode}:`} />
      <SkeletonWrapper isPending={!menuItemList.length} width={126} height={40}>
        <FormControl disabled={isDisabled}>
          <Select
            value={userMode}
            //   onChange={changeUserMode}
            children={menuItemList}
            size="small"
            sx={{ width: "126px" }}
          />
        </FormControl>
      </SkeletonWrapper>
    </Fragment>
  )
}
