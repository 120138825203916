import { useCallback } from "react"
import { ERequestStatus, TUseDataGridMethods, useDataGrid, useRestAPIReducer } from "shared"

import { eventJournalAPI } from "@api"
import { IJournalEventList } from "@types"

interface IUseEventJournalAPI {
  equipmentId?: number
  moduleId?: string
}

export interface IEventJournalAPI {
  journalEventList: IJournalEventList
  getJournalEventList(params?: string): void
  dataGridMethods: TUseDataGridMethods
  isPending: boolean
}

export const useEventJournalAPI = ({ equipmentId, moduleId }: IUseEventJournalAPI): IEventJournalAPI => {
  const [{ data: journalEventList, status }, actions] = useRestAPIReducer<IJournalEventList>({ results: [], count: 0 })

  const isPending = status === ERequestStatus.PENDING

  const getJournalEventList = useCallback((params?: string): void => {
    eventJournalAPI.getJournalEventList({ params: { reqString: params } }, actions)
  }, [])

  const dataGridMethods = useDataGrid(getJournalEventList, {
    customDefaultValues: { equipment: equipmentId?.toString(), module: moduleId },
    includeDateRange: true,
  })

  return { journalEventList, getJournalEventList, isPending, dataGridMethods }
}
