import React, { FC, useMemo } from "react"
import { DataGrid, excludeColumns } from "shared"

import { useLocalizationContext } from "@context"
import { getColumns } from "./_columns"
import { IEventJournalAPI } from "./_useEventJournalAPI"
import { useHadbookedRows } from "./_useHandbookedRows"

interface IEventJournal {
  eventJournalAPI: IEventJournalAPI
  excludeColumnList?: string[]
  height?: number | string
}

/**
 * Компонент таблица журнала событий
 *
 * @param {IEventJournalAPI} eventJournalAPI - API журнала событий
 * @param {string[]} excludeColumnList - Принимает массив заголовок таблицы для исключения из "Журнал событий"
 * @param {number | string} height - Задает высоту таблицы
 *
 * @returns {<IEventJournal>} - FC<IEventJournal>
 */
const EventJournal: FC<IEventJournal> = ({ eventJournalAPI, height, excludeColumnList }) => {
  const { l } = useLocalizationContext()

  const { journalEventList, dataGridMethods, isPending } = eventJournalAPI

  const rows = useHadbookedRows(journalEventList.results)

  const columns = useMemo(() => {
    const _columns = getColumns(l)

    return excludeColumnList ? excludeColumns(_columns, excludeColumnList) : _columns
  }, [l, excludeColumnList])

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      rowCount={journalEventList.count}
      checkboxSelection={false}
      paginationMethods={dataGridMethods.setValues}
      externalPageState={dataGridMethods.values.page - 1}
      externalPageSizeLimitState={dataGridMethods.values.limit}
      loading={isPending}
      rowHeight={38}
      height={height}
      l={l}
    />
  )
}

export { EventJournal }
